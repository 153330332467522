//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import { connect }      from 'react-redux';
import ComponentHelper  from '@/helper/ComponentHelper';
import PropTypes        from '@/components/PropTypes';
import * as Sentry      from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export class Component extends React.Component {
    render() {
        const { cookiesAccepted } = this.props;

        if (cookiesAccepted) {
            Sentry.init({
                dsn:          'https://d68bbd7fdd0da3e278b3ae6cbb7f09fd@sentry.lulububu.software/26',
                integrations: [
                    new Integrations.BrowserTracing(),
                ],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.25,
            });
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const SentryIo = Component;

Component.propTypes = {
    cookiesAccepted: PropTypes.bool,
};

Component.defaultProps = {
    cookiesAccepted: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        cookiesAccepted: state.cookieBanner.accepted,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
